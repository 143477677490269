import React from 'react'
import { Flex } from '@chakra-ui/react'

function Main({ children, ...restProps }) {
  return (
    <Flex direction="column" minHeight="100vh" {...restProps}>
      {children}
    </Flex>
  )
}

export default Main
