import React from 'react'
import { Link } from 'gatsby'

import { Box, Flex } from '@chakra-ui/react'

import logo from '../images/logo.svg'

function Header() {
  return (
    <Flex as="header" maxWidth="860px" marginX="auto" height={{ base: '60px', lg: '80px' }}>
      <Flex alignItems="center" justifyContent="center" width="100%">
        <Box as={Link} to="/" height="45px">
          <img style={{ height: '100%' }} src={logo} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Header
