import React from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'

import Main from '../components/main'
import Header from '../components/header'
import SEO from '../components/seo'

const globalStyles = css`
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  * {
    font-size: 14px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif !important;
    font-weight: 400;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  p,
  span {
    font-size: 16px;
    max-height: 999999px;
  }
`

function Layout({ children, title, description }) {
  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={title} description={description} />
      <Header />
      <Main>{children}</Main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

Layout.defaultProps = {
  title: '',
  description: '',
}

export default Layout
